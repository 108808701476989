/**
 * Type: ページ
 * What: よしもと福岡 大和証券劇場ページ
 */
import React from 'react';
// import { scroller } from 'react-scroll';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Btn, Button, Title } from '../components/Btn';
import Image from '../util/Image';
import BreadcrumbComponent from '../components/Breadcrumb';
import SimpleSwiper from '../components/Slider.fade';
import PostListComponent from '../components/PostList/PostList.yoshimoto';
import yoshimotoLogo from '../images/yoshimoto/btn-yoshimoto.png';

import useMediaQuery from '../util/useMediaQuery';

const mq = useMediaQuery;

const pageTitle = 'よしもと福岡 大和証券劇場';
const pageDescription = 'E・ZO FUKUOKAの7階。吉本興業の新たな常設劇場が福岡に誕生。2020年夏オープン予定。大阪「なんばグランド花月」、東京「ルミネtheよしもと」、京都「祇園花月」に次ぐ規模の、福岡に新たなエンターテインメント発信基地。';
const pageSlug = 'yoshimoto';
const pageLogo = `logo-${pageSlug}.png`;
const pageLogoSp = `logo-${pageSlug}-sp.png`;

export const query = graphql`
  {
    site {
      siteMetadata {
        uuidYoshimoto
        ticket
        ticketReady
      }
    }
  }
`;

const sliderDataobj = [
  {
    filename: 'main-yoshimoto.jpg',
  },
];

// Data Props Template
const Template = ({ data }) => {
  // const scrollToTarget = (e, elem, offset) => {
  //   e.preventDefault();
  //   const $offset = offset || -110;
  //   scroller.scrollTo(elem, {
  //     duration: 1200,
  //     delay: 0,
  //     offset: $offset,
  //     smooth: 'easeOutQuint',
  //   });
  // };
  const url = data.site.siteMetadata;
  return (
    <Layout pageSlug={pageSlug}>
      <SEO
        title={pageTitle}
        description={pageDescription}
      />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="pageMain bs-7 bg-12">
        <div className="kv-wrap">
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
          </div>
          <div className="container">
            <div className="inner">
              <div className="pageMain-header">
                <div className="floorNo c-7">
                  <span>7</span>
                  <span>F</span>
                </div>
                <h2 className="ttl">
                  {mq('pc') ? (
                    <Image src={pageLogo} alt={pageTitle} />
                  ) : (
                    <Image src={pageLogoSp} alt={pageTitle} />
                  )}
                </h2>
              </div>

              <div className="overview">
                <p className="text c-4">
                  吉本興業の新たな常設劇場が福岡に誕生！
                </p>
                <p className="text c-4">
                  大阪「なんばグランド花月」、京都「よしもと祇園花月」に次ぐ規模で、福岡のエンタメ情報発信基地として九州出身の芸人がここから新しいお笑い文化を生み出します！
                  <br />
                  ※全国で13館目の劇場(2020年7月1日現在) 座席数501席
                </p>
              </div>
              {/*
              <div className="section-link">
                <ul className="bs-7">>
                  <li>
                    <a
                      href="#place"
                      onClick={(e) => scrollToTarget(e, 'place')}
                    >
                      <span
                        className="arrow"
                      >
                        劇場紹介
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${url.ticket}#/order?lang=ja&id=${url.uuidYoshimoto}`}
                      className="btn-ticket2 bc-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span
                        className="arrow"
                      >
                        チケット購入
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              */}
            </div>
          </div>
        </div>
      </section>
      <PostListComponent />
      <section className="section bg-19" id="place">
        <div className="container">
          <div className="inner">

            <div
              className="btn-wrap"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              {url.ticket ? (
                <a
                  href="https://fukuokagekijyo.yoshimoto.co.jp/"
                  className="btn-regular brand"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <img src={yoshimotoLogo} alt="" />
                  </span>
                  <span>詳しくは公式HPまで</span>
                  <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
                    <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                    <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                  </svg>
                </a>
              ) : null}
            </div>

            {/*
          <h2 className="title is-2 en2 shadow-3">
            <Title>Theater</Title>
          </h2>
          */}

            {/*
          <p className="text2 mb50">
            {`大阪「なんばグランド花月」、東京「ルミネtheよしもと」、京都「祇園花月」に次ぐ規模で、福岡に新たなエンターテインメント発信基地として吉本興業の常設劇場「${pageTitle}」が誕生。`}
          </p>
          */}

            {/*
          <div className="sc-columns mb60">
            <div
              className="col col-12 floorbc7 none"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="now-printing.jpg" />
                  </figure>

                </div>
                <div className="col-content">
                  <h3 className="ttl fsz14 c-7">テキストテキストテキスト</h3>
                  <p className="text fsz1">
                    テキストテキストテキストテキスト
                    テキストテキストテキストテキスト
                    テキストテキストテキストテキスト
                    テキストテキストテキストテキスト
                    テキストテキストテキストテキスト
                    テキストテキストテキストテキストテキストテキストテキスト
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col col-6 floorbc7 none"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="now-printing.jpg" />
                  </figure>

                </div>
                <div className="col-content">
                  <h3 className="ttl fsz14 c-7">テキストテキスト</h3>
                  <p className="text fsz1">
                    テキストテキストテキストテキスト
                    テキストテキストテキストテキスト
                    テキストテキストテキストテキスト
                    テキストテキストテキストテキスト
                    テキストテキストテキストテキストテキストテキスト
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col col-6 floorbc7 none"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="now-printing.jpg" />
                  </figure>

                </div>
                <div className="col-content">
                  <h3 className="ttl fsz14 c-7">テキスト</h3>
                  <p className="text fsz1">
                    テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                  </p>
                </div>
              </div>
            </div>
          </div>
          */}

            {/*
          <div className="content-box bg-grey2">
            <h2 className="title is-3 en2 c-17 has-text-align-center">Attention</h2>
            <div className="content">
              <h3 className="subtitle is-5">注意事項</h3>
              <p className="list-mark2">
                ※ ダミーテキストです。これは仮のテキストになりますので後ほど差し替えになります。
                ダミーテキストです。これは仮のテキストになりますので後ほど差し替えになります。
              </p>
              <p className="list-mark2">
                ※ ダミーテキストです。これは仮のテキストになりますので後ほど差し替えになります。
                ダミーテキストです。これは仮のテキストになりますので後ほど差し替えになります。
              </p>

              <h3 className="subtitle is-5">お願い</h3>
              <p className="list-mark2">
                ※ ダミーテキストです。これは仮のテキストになりますので後ほど差し替えになります。
                ダミーテキストです。これは仮のテキストになりますので後ほど差し替えになります。
              </p>
              <p className="list-mark2">
                ※ ダミーテキストです。これは仮のテキストになりますので後ほど差し替えになります。
                ダミーテキストです。これは仮のテキストになりますので後ほど差し替えになります。
              </p>
              <p className="list-mark2">
                ※ ダミーテキストです。これは仮のテキストになりますので後ほど差し替えになります。
                ダミーテキストです。これは仮のテキストになりますので後ほど差し替えになります。
              </p>

            </div>
          </div>
          */}

          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="inner">
            {/* <div className="columns">
              <div className="column">
                <figure className="image">
                  <Image filename="img-yoshimoto-entrance.png" />
                </figure>
              </div>
            </div>
            <p className="is-size-5 has-text-weight-bold has-text-align-center mb-6 has-text-black">劇場エントランス</p> */}
            <div className="columns">
              <div className="column">
                <figure className="image">
                  <Image filename="img-yoshimoto-theater01.png" />
                </figure>
              </div>
              <div className="column">
                <figure className="image">
                  <Image filename="img-yoshimoto-theater02.png" />
                </figure>
              </div>
            </div>
            <p className="is-size-5 has-text-weight-bold has-text-align-center has-text-black">内観（座席数: 501）</p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Template;
